body {
  font-family: "Poppins", sans-serif;
}
* {
  font-family: "Poppins", sans-serif;
}

/* *{
  outline: 1px solid red;
} */
/* input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #0d0d0f inset !important;
  -webkit-text-fill-color: white !important;
} */

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:first-line,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:first-line,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid gray; */
  -webkit-text-fill-color: white;
  /* -webkit-box-shadow: 0 0 0px 1000px #0d0d0f inset; */
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

#toggle-switch::after {
  content: "";
  @apply absolute top-0 left-0 w-6 h-6 transition duration-200 transform scale-90 bg-white rounded-full;
}

#toggle-switch:checked::after {
  @apply transform scale-90 translate-x-6;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  /* Remove dropdown from number input */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }

  /* Scrollbar dark mobile */
  .scrollbarMobile::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  .scrollbarMobile::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
  }

  .scrollbarMobile::-webkit-scrollbar-thumb {
    background: #0d0d0f;
    border-radius: 100vh;
    border: 1px solid #555555;
  }

  .scrollbarMobile::-webkit-scrollbar-thumb:hover {
    background: #343a40;
  }

  /* Scrollbar dark */
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #0d0d0f;
    border-radius: 100vh;
    border: 1px solid #bebebf;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #343a40;
  }

  /* Scrollbar Light Mobile */
  .scrollbarLightMobile::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  .scrollbarLightMobile::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
  }

  .scrollbarLightMobile::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 100vh;
    border: 1px solid #bebebf;
  }

  .scrollbarLightMobile::-webkit-scrollbar-thumb:hover {
    background: #7c7c7c;
  }
  /* Scrollbar Light */
  .scrollbarLight::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .scrollbarLight::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
  }

  .scrollbarLight::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 100vh;
    border: 1px solid #bebebf;
  }

  .scrollbarLight::-webkit-scrollbar-thumb:hover {
    background: #7c7c7c;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* .rounded-10-important {
    border-radius: 100vh !important;
  } */

  .line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .otpBoxStyle {
    height: 60px !important;
    width: 50px !important;
    padding-left: 5px !important;
    color: white;
    font-weight: bold;
  }
  @media (max-width: 640px) {
    .otpBoxStyle {
      height: 50px !important;
      width: 50px !important;
      padding-left: 3px !important;
    }
  }

  .otpInputColorLight {
    color: #374151 !important;
  }
  .tooltipOpacity {
    opacity: 1 !important;
  }
  .catchmentHeight {
    height: 82vh;
  }
  .sensor-scale-06 {
    transform: scale(0.6);
  }
  .sensor-scale-1 {
    transform: scale(1);
  }
  .w-500px {
    width: 500px;
  }
  .mt-15prcnt {
    margin-top: 15%;
  }
  .sensor-text-color {
    color: rgb(102, 104, 7);
  }
  .mixChart__main {
    padding-top: 10px;
    width: 99%;
  }
  .modalStyle {
    /* left: 15%; */
    /* top: 30%; */
    transition: all 0.3s ease-out;
  }
  .all__sensor__shadow {
    box-shadow: -5px 0px 9px -8px #b3b3b8, 5px 0px 9px -8px #b3b3b8,
      0px -5px 9px -8px #b3b3b8, 0px 5px 9px -8px #b3b3b8;
  }
  .w-550px {
    width: 550px;
  }
  .w-355px {
    width: 355px;
  }
  .ml-13prcnt {
    margin-left: 13%;
  }
  .mr-1prcnt {
    margin-right: 1%;
  }
  .select {
    text-align: left;
    max-width: 600px;
    width: 400px;
  }
  .h-350px {
    height: 350px;
  }
  .h-95 {
    height: 95% !important;
  }
  .h-92 {
    height: 92% !important;
  }
  .pt-30vh {
    padding-top: 30vh;
  }
  .mt-20prcnt {
    margin-top: 20%;
  }
  .h-85vh {
    height: 77vh !important;
  }
  .h-95vh {
    height: 95vh !important;
  }
  .h-78vh {
    height: 77.5vh !important;
  }
  .min-h-78vh {
    min-height: 77.5vh !important;
  }
  .max-w-70prcnt {
    max-width: 70%;
  }
  .max-w-72prcnt {
    max-width: 72%;
  }
  .w-sidebar {
    width: 350px;
  }
  .w-toggleBar {
    width: 24%;
  }
  .tooltipZ{
    z-index: 1001 !important;
  }
  .z-100 {
    z-index: 100;
  }
  .z-200 {
    z-index: 200;
  }
  .z-1000 {
    z-index: 1000;
  }
  .z-1001 {
    z-index: 1001;
  }
  .br {
    border: 1px solid red;
  }
  .bb {
    border: 1px solid blue;
  }
  .bg {
    border: 1px solid green;
  }
  .bp {
    border: 1px solid pink;
  }
  .by {
    border: 1px solid yellow;
  }
  .hdc-box {
    box-shadow: 0px 0px 5px 2px #ffffff33;
  }
  .glow-spekterGreen {
    border-color: #00ff38;
    box-shadow: 0 0 10px #00ff38;
  }
  .glow-spekterYellow {
    border-color: #fff500;
    box-shadow: 0 0 10px #fff500;
  }
  .glow-spekterYellow {
    border-color: #ff0000;
    box-shadow: 0 0 10px #ff0000;
  }
  .glow-spekterPurple {
    border-color: #6e00ff;
    box-shadow: 0 0 10px #6e00ff;
  }
  .phoneInput input {
    background: #c9c9c9 !important;
  }
  .phoneInput input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background: #c9c9c9 !important;
  }

  .phoneInputDark input {
    background-color: #333;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .phoneInputSubs input {
    background: #c9c9c9 !important;
  }
  .phoneInputSubs input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background: #c9c9c9 !important;
  }

  .phoneInputDarkSubs input {
    background-color: #333;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .phoneInputOld input {
    background: #fff !important;
  }
  .phoneInputOld input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background: #fff !important;
  }

  .phoneInputDarkOld input {
    background-color: #343a40 !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  .notificationBg {
    background: rgba(0, 0, 0, 0.69);
  }
  .notificationBg-light {
    background: rgba(255, 255, 255, 0.69);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.23);
  }
  .catchmentIconShadow-light {
    filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.23));
  }
  .catchmentSensorBgS1 {
    background: linear-gradient(
        90deg,
        rgba(242, 203, 83, 0.23) 0%,
        rgba(242, 203, 83, 0.09) 100%
      ),
      rgba(0, 0, 0, 0.69);
  }
  .catchmentSensorBgS1-light {
    background: linear-gradient(
        90deg,
        rgba(242, 203, 83, 0.23) 0%,
        rgba(242, 203, 83, 0.09) 100%
      ),
      rgba(255, 255, 255, 0.69);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.23);
  }
  .catchmentSensorBgS2-light {
    background: linear-gradient(
        90deg,
        rgba(228, 25, 49, 0.23) 0%,
        rgba(228, 25, 49, 0.09) 100%
      ),
      rgba(255, 255, 255, 0.69);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.23);
  }
  .catchmentSensorBgS2 {
    background: linear-gradient(
        90deg,
        rgba(228, 25, 49, 0.23) 0%,
        rgba(228, 25, 49, 0.09) 100%
      ),
      rgba(0, 0, 0, 0.69);
  }
  .catchmentSensorBgS3-light {
    background: linear-gradient(
        90deg,
        rgba(159, 51, 229, 0.23) 0%,
        rgba(159, 51, 229, 0.09) 100%
      ),
      rgba(255, 255, 255, 0.69);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.23);
  }
  .catchmentSensorBgS3 {
    background: linear-gradient(
        90deg,
        rgba(159, 51, 229, 0.23) 0%,
        rgba(159, 51, 229, 0.09) 100%
      ),
      rgba(0, 0, 0, 0.69);
  }
  .leftSideBarShadowLight {
    box-shadow: 0px 0px 47px 0px rgba(0, 0, 0, 0.23);
  }
  .containerShadowLight {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.23);
  }
  .containerShadowLightMobile {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.23);
  }
  .headerShadowLight {
    box-shadow: 0px 0px 47px 0px rgba(0, 0, 0, 0.23);
  }
  .buttonShadowLight {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.23);
  }
  .headerBgBlur {
    backdrop-filter: blur(61.5px);
    -webkit-backdrop-filter: blur(61.5px);
  }
  .background-layer-dark::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(61.5px);
    -webkit-backdrop-filter: blur(61.5px);
    background: rgba(0, 0, 0, 0.69);
    z-index: -1;
  }
  .background-layer-light::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(61.5px);
    -webkit-backdrop-filter: blur(61.5px);
    background: rgba(255, 255, 255, 0.69);
    z-index: -1;
  }
  .user-modal-bg-dark::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(61.5px);
    -webkit-backdrop-filter: blur(61.5px);
    background: rgba(29, 30, 33, 0.95);
    z-index: -1;
  }
  .user-modal-bg-light::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(61.5px);
    -webkit-backdrop-filter: blur(61.5px);
    background: rgba(236, 236, 236, 0.95);
    z-index: -1;
  }

  /* about modal */
  .background-about-layer-dark::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4.5px);
    -webkit-backdrop-filter: blur(4.5px);
    background: rgba(0, 0, 0, 0.09);
    z-index: -1;
  }

  .background-about-layer-light::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4.5px);
    -webkit-backdrop-filter: blur(4.5px);
    background: rgba(255, 255, 255, 0.09);
    z-index: -1;
  }
  .barBG {
    background: linear-gradient(
      90deg,
      rgba(0, 255, 77, 1) 0%,
      rgba(255, 255, 0, 1) 20%,
      rgba(255, 0, 0, 1) 66%,
      rgba(142, 0, 255, 1) 96%
    );
  }
  .s1BarBg {
    background: linear-gradient(90deg, #f6db88 0%, #a78c39 100%);
  }
  .s2BarBg {
    background: linear-gradient(90deg, #ec6071 0%, #9d1122 100%);
  }
  .s3BarBg {
    background: linear-gradient(90deg, #bd72ed 0%, #6e239e 100%);
  }
  .s0BarBg {
    background: #04cf00;
  }
  .inputShadowGreen {
    box-shadow: inset 0px 0px 10px #77faab;
    -webkit-appearance: none;
  }
  .inputShadowRed {
    box-shadow: inset 0px 0px 10px #fa7777;
    -webkit-appearance: none;
  }
}
